import React from 'react';
import { connect } from 'react-redux';
import { withRoomContext } from '../../RoomContext';

class MeetingEndModal extends React.PureComponent{

	constructor(props)
	{
		super(props);
		var errorUrl = props.room.errorUrl;
		var surveyUrl = props.room.feedbackUrl;
		if(surveyUrl){
			window.location = surveyUrl;
		} else {
			window.location = errorUrl;
		}
	}
    render(){
	//	const { room } = this.props;
        return (
			 <div></div>
            //  window.location = room.errorUrl
        );
    }
}

const mapStateToProps = (state) =>
	({
		room              : state.room,
		me				  : state.me
	});

export default withRoomContext(connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room
			);
		}
	}
)(MeetingEndModal));