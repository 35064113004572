export const setRoomName = (name) =>
	({
		type    : 'SET_ROOM_NAME',
		payload : { name }

	});

export const setRoomState = (state) =>
	({
		type    : 'SET_ROOM_STATE',
		payload : { state }

	});

export const setRoomActiveSpeaker = (peerId) =>
	({
		type    : 'SET_ROOM_ACTIVE_SPEAKER',
		payload : { peerId }
	});

export const setRoomLocked = () =>
	({
		type : 'SET_ROOM_LOCKED'
	});

export const setRoomUnLocked = () =>
	({
		type : 'SET_ROOM_UNLOCKED'
	});

export const setInLobby = (inLobby) =>
	({
		type    : 'SET_IN_LOBBY',
		payload : { inLobby }
	});

export const setSignInRequired = (signInRequired) =>
	({
		type    : 'SET_SIGN_IN_REQUIRED',
		payload : { signInRequired }
	});

export const setOverRoomLimit = (overRoomLimit) =>
	({
		type    : 'SET_OVER_ROOM_LIMIT',
		payload : { overRoomLimit }
	});

export const setAccessCode = (accessCode) =>
	({
		type    : 'SET_ACCESS_CODE',
		payload : { accessCode }
	});

export const setJoinByAccessCode = (joinByAccessCode) =>
	({
		type    : 'SET_JOIN_BY_ACCESS_CODE',
		payload : { joinByAccessCode }
	});

export const setSettingsOpen = (settingsOpen) =>
	({
		type    : 'SET_SETTINGS_OPEN',
		payload : { settingsOpen }
	});

export const setExtraVideoOpen = (extraVideoOpen) =>
	({
		type    : 'SET_EXTRA_VIDEO_OPEN',
		payload : { extraVideoOpen }
	});

export const setHelpOpen = (helpOpen) =>
	({
		type    : 'SET_HELP_OPEN',
		payload : { helpOpen }
	});

export const setAboutOpen = (aboutOpen) =>
	({
		type    : 'SET_ABOUT_OPEN',
		payload : { aboutOpen }
	});

export const setSettingsTab = (tab) =>
	({
		type    : 'SET_SETTINGS_TAB',
		payload : { tab }
	});

export const setLockDialogOpen = (lockDialogOpen) =>
	({
		type    : 'SET_LOCK_DIALOG_OPEN',
		payload : { lockDialogOpen }
	});

export const setFileSharingSupported = (supported) =>
	({
		type    : 'FILE_SHARING_SUPPORTED',
		payload : { supported }
	});

export const toggleConsumerWindow = (consumerId) =>
	({
		type    : 'TOGGLE_WINDOW_CONSUMER',
		payload : { consumerId }
	});

export const setToolbarsVisible = (toolbarsVisible) =>
	({
		type    : 'SET_TOOLBARS_VISIBLE',
		payload : { toolbarsVisible }
	});

export const setDisplayMode = (mode) =>
	({
		type    : 'SET_DISPLAY_MODE',
		payload : { mode }
	});

export const setSelectedPeer = (selectedPeerId) =>
	({
		type    : 'SET_SELECTED_PEER',
		payload : { selectedPeerId }
	});

export const setSpotlights = (spotlights) =>
	({
		type    : 'SET_SPOTLIGHTS',
		payload : { spotlights }
	});

export const clearSpotlights = () =>
	({
		type : 'CLEAR_SPOTLIGHTS'
	});

export const toggleJoined = () =>
	({
		type : 'TOGGLE_JOINED'
	});

export const toggleConsumerFullscreen = (consumerId) =>
	({
		type    : 'TOGGLE_FULLSCREEN_CONSUMER',
		payload : { consumerId }
	});

export const setLobbyPeersPromotionInProgress = (flag) =>
	({
		type    : 'SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS',
		payload : { flag }
	});

export const setMuteAllInProgress = (flag) =>
	({
		type    : 'MUTE_ALL_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllVideoInProgress = (flag) =>
	({
		type    : 'STOP_ALL_VIDEO_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllScreenSharingInProgress = (flag) =>
	({
		type    : 'STOP_ALL_SCREEN_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setCloseMeetingInProgress = (flag) =>
	({
		type    : 'CLOSE_MEETING_IN_PROGRESS',
		payload : { flag }
	});

export const setClearChatInProgress = (flag) =>
	({
		type    : 'CLEAR_CHAT_IN_PROGRESS',
		payload : { flag }
	});

export const setClearFileSharingInProgress = (flag) =>
	({
		type    : 'CLEAR_FILE_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setRoomPermissions = (roomPermissions) =>
	({
		type    : 'SET_ROOM_PERMISSIONS',
		payload : { roomPermissions }
	});

export const setAllowWhenRoleMissing = (allowWhenRoleMissing) =>
	({
		type    : 'SET_ALLOW_WHEN_ROLE_MISSING',
		payload : { allowWhenRoleMissing }
	});

export const setChatRoom = (chatWindow) =>
	({
		type : 'SET_CHAT_ROOM',
		payload : { chatWindow }
	});

export const setAttachmentRoom = (attachmentWindow) =>
	({
		type : 'SET_ATTACHMENT_ROOM',
		payload : { attachmentWindow }
	});

export const setParticipantsRoom = (participantsWindow) =>
	({
		type : 'SET_PARTICIPANTS_ROOM',
		payload : { participantsWindow }
	});
export const setRoomHostId = (hostId) =>
	({
		type    : 'SET_ROOM_HOST_ID',
		payload : { hostId }
	});

export const setStartMeetingTime = (startMeetingTime) =>
	({
		type    : 'SET_START_MEETING_TIME',
		payload : { startMeetingTime }
	});
export const setEndMeetingTime = (endMeetingTime) =>
	({
		type    : 'SET_END_MEETING_TIME',
		payload : { endMeetingTime }
	});
export const setRoomMeetingTimeDiff = (meetingTimerDiff) =>
	({
		type    : 'SET_MEETING_TIME_DIFF',
		payload : { meetingTimerDiff }
	});

export const setRoomMeetingTime = (meetingTimer) =>
	({
		type    : 'SET_MEETING_TIME',
		payload : { meetingTimer }
	});

export const setRoomMeetingTimeExtended = (timeExtended) =>
	({
		type    : 'SET_MEETING_TIME_EXTENDED',
		payload : { timeExtended }
	});
export const setRoomMeetingTimeExtendedMin = (extentedMinutes) =>
	({
		type    : 'SET_MEETING_TIME_EXTENDED_MIN',
		payload : { extentedMinutes }
	});

export const setRoomJoined = (joined) =>
	({
		type    : 'SET_ROOM_JOINED',
		payload : { joined }
	});

export const setRoomLogo = ( logo) =>
	({
		type    : 'SET_ROOM_LOGO',
		payload : { logo }
	});

export const setRoomTitle = ( title) =>
	({
		type    : 'SET_ROOM_TITLE',
		payload : { title }
	});

export const setRoomErrorUrl = ( errorUrl ) =>
	({
		type    : 'SET_ROOM_ERROR_URL',
		payload : { errorUrl }
	});

export const setRoomFeedbackUrl = ( feedbackUrl ) =>
	({
		type    : 'SET_ROOM_FEEDBACK_URL',
		payload : { feedbackUrl }
	});

export const setRoomCurrentTime = ( currentTime ) =>
	({
		type    : 'SET_ROOM_CURRENT_TIME',
		payload : { currentTime }
	});
	export const setRoomRemainingHours = ( remainingHours ) =>
	({
		type    : 'SET_ROOM_REMAINING_HOURS',
		payload : { remainingHours }
	});	

	export const setRoomRemainingMinutes = ( remainingMinutes ) =>
	({
		type    : 'SET_ROOM_REMAINING_MINUTES',
		payload : { remainingMinutes }
	});	
	export const setRoomRemainingSeconds = ( remainingSeconds ) =>
	({
		type    : 'SET_ROOM_REMAINING_SECONDS',
		payload : { remainingSeconds }
	});