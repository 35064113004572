import domready from 'domready';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import isElectron from 'is-electron';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { Route, HashRouter, BrowserRouter } from 'react-router-dom';
import randomString from 'random-string';
import Logger from './Logger';
import debug from 'debug';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import deviceInfo from './deviceInfo';
import * as meActions from './actions/meActions';
import * as roomActions from './actions/roomActions';
import * as settingsActions from './actions/settingsActions';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import ChooseRoom from './components/ChooseRoom';
import LoadingView from './components/LoadingView';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { ReactLazyPreload } from './components/ReactLazyPreload';
import { detectDevice } from 'mediasoup-client';
// import messagesEnglish from './translations/en';
import messagesNorwegian from './translations/nb';
import messagesGerman from './translations/de';
import messagesHungarian from './translations/hu';
import messagesPolish from './translations/pl';
import messagesDanish from './translations/dk';
import messagesFrench from './translations/fr';
import messagesGreek from './translations/el';
import messagesRomanian from './translations/ro';
import messagesPortuguese from './translations/pt';
import messagesChineseSimplified from './translations/cn';
import messagesChineseTraditional from './translations/tw';
import messagesSpanish from './translations/es';
import messagesCroatian from './translations/hr';
import messagesCzech from './translations/cs';
import messagesItalian from './translations/it';
import messagesUkrainian from './translations/uk';
import messagesTurkish from './translations/tr';
import messagesLatvian from './translations/lv';
// import TimeExtendModal from './components/Controls/TimeExtendModal';
import moment from "moment";
import './index.css';
import MeetingEndModal from './components/Controls/MeetingEndModal';

const axios = require('axios');

const App = ReactLazyPreload(() => import(/* webpackChunkName: "app" */ './components/App'));

const cache = createIntlCache();

const messages =
{
	// 'en' : messagesEnglish,
	'nb'      : messagesNorwegian,
	'de'      : messagesGerman,
	'hu'      : messagesHungarian,
	'pl'      : messagesPolish,
	'dk'      : messagesDanish,
	'fr'      : messagesFrench,
	'el'      : messagesGreek,
	'ro'      : messagesRomanian,
	'pt'      : messagesPortuguese,
	'zh-hans' : messagesChineseSimplified,
	'zh-hant' : messagesChineseTraditional,
	'es'      : messagesSpanish,
	'hr'      : messagesCroatian,
	'cs'      : messagesCzech,
	'it'      : messagesItalian,
	'uk'      : messagesUkrainian,
	'tr'      : messagesTurkish,
	'lv'      : messagesLatvian
};

const supportedBrowsers={
	'windows' : {
		'internet explorer' : '>12',
		'microsoft edge'    : '>18'
	},
	'safari'                       : '>12',
	'firefox'                      : '>=60',
	'chrome'                       : '>=74',
	'chromium'                     : '>=74',
	'opera'                        : '>=62',
	'samsung internet for android' : '>=11.1.1.52'
};

const browserLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();

let locale = browserLanguage.split(/[-_]/)[0]; // language without region code

if (locale === 'zh')
{
	if (browserLanguage === 'zh-cn')
		locale = 'zh-hans';
	else
		locale = 'zh-hant';
}

const intl = createIntl({
	locale,
	messages : messages[locale]
}, cache);

document.documentElement.lang = locale;

if (process.env.REACT_APP_DEBUG === '*' || process.env.NODE_ENV !== 'production')
{
	debug.enable('* -engine* -socket* -RIE* *WARN* *ERROR*');
}

const logger = new Logger();

let roomClient;

RoomClient.init({ store, intl });

const theme = createMuiTheme(window.config.theme);

let Router;

if (isElectron())
	Router = HashRouter;
else
	Router = BrowserRouter;

domready(() =>
{
	logger.debug('DOM ready');

	run();
});

async function validateMeeting(parameters){
	var participantId = parameters.get('participant_id'); 
	var meetingId = parameters.get('meeting_id');
	var key = parameters.get('key');
	var test = parameters.get('test');
	try{
		const { data } = await axios({
			method: "post",
			url: `${process.env.REACT_APP_MEETING_URL}`+"validate-meeting",
			crossDomain: true,
			data:  {
				'meeting_id' 		: meetingId,
				'participant_id'	: participantId,
				'key'      			: key,
				'test'				: test
			}
		});
		
		return data;
	}catch (error){
		logger.error(' error on REST turn [error:"%o"]', error);
		return false;
	}
}
function run()
{
	logger.debug('run() [environment:%s]', process.env.NODE_ENV);

	const peerId = randomString({ length: 8 }).toLowerCase();
	const urlParser = new URL(window.location);
	const parameters = urlParser.searchParams;

	const accessCode = parameters.get('code');
	const produce = parameters.get('produce') !== 'false';
	const forceTcp = parameters.get('forceTcp') === 'true';
	var displayName = parameters.get('displayName');
	const muted = parameters.get('muted') === 'true';

	const { pathname } = window.location;

	let basePath = pathname.substring(0, pathname.lastIndexOf('/'));

	if (!basePath)
		basePath = '/';

	// Get current device.
	const device = deviceInfo();

	let unsupportedBrowser = false;

	let webrtcUnavailable = false;

	if (detectDevice() === undefined)
	{
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

		unsupportedBrowser = true;
	}
	else if (
		navigator.mediaDevices === undefined ||
		navigator.mediaDevices.getUserMedia === undefined ||
		window.RTCPeerConnection === undefined
	)
	{
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

		webrtcUnavailable = true;
	}
	else if (
		!device.bowser.satisfies(
			window.config.supportedBrowsers || supportedBrowsers
		)
	)
	{
		logger.error(
			'Your browser is not supported [deviceInfo:"%o"]',
			device
		);

		unsupportedBrowser = true;
	}
	else
	{
		logger.debug('Your browser is supported [deviceInfo:"%o"]', device);
	}

	if (unsupportedBrowser || webrtcUnavailable)
	{
		render(
			<MuiThemeProvider theme={theme}>
				<RawIntlProvider value={intl}>
					<UnsupportedBrowser
						webrtcUnavailable={webrtcUnavailable}
						platform={device.platform}
					/>
				</RawIntlProvider>
			</MuiThemeProvider>,
			document.getElementById('multiparty-meeting')
		);

		return;
	}
	roomClient = new RoomClient(
		{
			peerId,
			accessCode,
			device,
			produce,
			forceTcp,
			displayName,
			muted,
			basePath
		});

	global.CLIENT = roomClient;
	var meetingEnd = false;
	var participantId = parameters.get('participant_id'); 
	var meetingId = parameters.get('meeting_id');
	var key = parameters.get('key');
	if(!participantId || !meetingId || !key) {

			var errorUrl = `${process.env.REACT_APP_MEETING_URL}`+"error?message=test";
			store.dispatch(roomActions.setRoomErrorUrl(errorUrl));
			store.dispatch(roomActions.setRoomFeedbackUrl());
			render(
				<Provider store={store}>
					<MuiThemeProvider theme={theme}>
						<RawIntlProvider value={intl}>
							<PersistGate loading={<LoadingView />} persistor={persistor}>
								<RoomContext.Provider value={roomClient}>
									<SnackbarProvider>
										<Router basename={basePath}>
											<Suspense fallback={<LoadingView />}>
												<React.Fragment>
													{/* <Route exact path='/' component={ChooseRoom} />
													<Route path='/:id' component={App} /> */}
													<MeetingEndModal textData={'Error'} />
												</React.Fragment>
											</Suspense>
										</Router>
									</SnackbarProvider>
								</RoomContext.Provider>
							</PersistGate>
						</RawIntlProvider>
					</MuiThemeProvider>
				</Provider>,
				document.getElementById('multiparty-meeting')
			);
	}
	validateMeeting(parameters).then(function(result) {
		 console.log(result, 'result data')

	
		// result = {
		// 	"flag": true,
		// 	"message": "Participant found!",
		// 	"data": {
		// 	"date_of_meeting": "2020-09-06T00:00:00.000000Z",
		// 	"start_of_meeting": "2020-09-06T04:34:22Z",
		// 	"end_of_meeting": "2020-09-06T17:34:22Z",
		// 	"duration_of_meeting": 240,
		// 	"event_id": 26,
		// 	"event_name": "Test Index 2020",
		// 	"event_logo": "https://indexholding.ae/wp-content/uploads/2018/10/index-holding-1.png",
		// 	"extendable": true,
		// 	"extendable_time": 5,
		// 	"meeting_id": 2,
		// 	"registration_id": 413418,
		// 	"user_type": "host",
		// 	"registration_first_name": "Abakan",
		// 	"registration_last_name": "Marazhapov "+peerId,
		// 	"registration_image": "https://static.thenounproject.com/png/17241-200.png",
		// 	"current_time": "12:00:03"
		// 	}
		//    };
		// console.log(result);

		if(result.flag === false && result.message){
			store.dispatch(roomActions.setRoomErrorUrl(result.data.event_url));
			store.dispatch(roomActions.setRoomFeedbackUrl(""));
			render(
				<Provider store={store}>
					<MuiThemeProvider theme={theme}>
						<RawIntlProvider value={intl}>
							<PersistGate loading={<LoadingView />} persistor={persistor}>
								<RoomContext.Provider value={roomClient}>
									<SnackbarProvider>
										<Router basename={basePath}>
											<Suspense fallback={<LoadingView />}>
												<React.Fragment>
													{/* <Route exact path='/' component={ChooseRoom} />
													<Route path='/:id' component={App} /> */}
													<MeetingEndModal textData={result.message} />
												</React.Fragment>
											</Suspense>
										</Router>
									</SnackbarProvider>
								</RoomContext.Provider>
							</PersistGate>
						</RawIntlProvider>
					</MuiThemeProvider>
				</Provider>,
				document.getElementById('multiparty-meeting')
			);
		} else if(result.flag === true && result.data){
			
			var isHost = result.data.user_type;
			var roles = ['normal'];
			if(isHost === "host"){
				roles = ['admin', 'moderator'];
			} else if(isHost === "invitee"){
				roles = ['presenter', 'normal'];
			} else {
				roles = ['normal'];
			}

			store.dispatch(
				meActions.setMe({
					peerId,
					loginEnabled : window.config.loginEnabled,
					isHost: isHost,
					roles: roles
				})
			);
			var currentTime = moment.utc(result.data.current_time,'HH:mm:ss').add(4,'hour').format('HH:mm:ss');
			
			displayName = result.data.registration_first_name+" "+result.data.registration_last_name;
			store.dispatch(roomActions.setRoomHostId(result.data.registration_id));
			store.dispatch(roomActions.setStartMeetingTime(result.data.start_of_meeting));
			store.dispatch(roomActions.setEndMeetingTime(result.data.end_of_meeting));
			store.dispatch(roomActions.setRoomMeetingTime(result.data.duration_of_meeting));
			store.dispatch(roomActions.setRoomMeetingTimeDiff(result.data.duration_of_meeting));
			store.dispatch(roomActions.setRoomMeetingTimeExtended(result.data.extendable));
			store.dispatch(roomActions.setRoomMeetingTimeExtendedMin(result.data.extendable_time));
			store.dispatch(roomActions.setRoomLogo(result.data.event_logo));
			store.dispatch(roomActions.setRoomTitle(result.data.event_name));
			store.dispatch(roomActions.setRoomErrorUrl(result.data.event_url));
			store.dispatch(roomActions.setRoomFeedbackUrl(result.data.survey_url));	
			store.dispatch(roomActions.setRoomCurrentTime(currentTime));

			store.dispatch(roomActions.setRoomRemainingHours(result.data.remaining_hours));
			store.dispatch(roomActions.setRoomRemainingMinutes(result.data.remaining_minutes));
			store.dispatch(roomActions.setRoomRemainingSeconds(result.data.remaining_seconds));

			
			store.dispatch(roomActions.setRoomUnLocked());
			
			// if(isHost === 'host'){
			// 	store.dispatch(roomActions.setRoomUnLocked());
			// 	// store.dispatch(roomActions.setInLobby(false));
			// 	//store.dispatch(roomActions.setRoomJoined(true));
			// 	// roomClient.join({ roomId, joinVideo: false });
			// }
			if (displayName)
				store.dispatch(settingsActions.setDisplayName(displayName));
			if (result.data.registration_image)
				store.dispatch(meActions.setPicture(result.data.registration_image));
				
			var date = new Date(result.data.end_of_meeting);
			var endTime = moment(date, "hh:mm:ss");
			var startTime = moment(currentTime, "hh:mm:ss");
			var ms = moment(endTime,"HH:mm:ss").diff(moment(startTime,"HH:mm:ss"));
			var d = moment.duration(ms);

			var hours =  d.hours();
			var minutes = d.minutes();
			var seconds =  d.seconds();
			var endDate = moment(date).format("YYYY/MM/DD");
			var currentDate = moment(new Date()).format("YYYY/MM/DD");
		//	return;
			// if((hours <= 0 && minutes <= 0 && seconds <= 0) || (endDate !== currentDate)){
			if(false){
				render(
					<Provider store={store}>
						<MuiThemeProvider theme={theme}>
							<RawIntlProvider value={intl}>
								<PersistGate loading={<LoadingView />} persistor={persistor}>
									<RoomContext.Provider value={roomClient}>
										<SnackbarProvider>
											<Router basename={basePath}>
												<Suspense fallback={<LoadingView />}>
													<React.Fragment>
														{/* <Route exact path='/' component={ChooseRoom} />
														<Route path='/:id' component={App} /> */}
														<MeetingEndModal  textData="Meeting is already end!" /> 
													</React.Fragment>
												</Suspense>
											</Router>
										</SnackbarProvider>
									</RoomContext.Provider>
								</PersistGate>
							</RawIntlProvider>
						</MuiThemeProvider>
					</Provider>,
					document.getElementById('multiparty-meeting')
				);
			}  else {
				render(
					<Provider store={store}>
						<MuiThemeProvider theme={theme}>
							<RawIntlProvider value={intl}>
								<PersistGate loading={<LoadingView />} persistor={persistor}>
									<RoomContext.Provider value={roomClient}>
										<SnackbarProvider>
											<Router basename={basePath}>
												<Suspense fallback={<LoadingView />}>
													<React.Fragment>
														<Route exact path='/' component={ChooseRoom} />
														<Route path='/:id' component={App} />
														{
															meetingEnd && 
															<MeetingEndModal  />
														}
													</React.Fragment>
												</Suspense>
											</Router>
										</SnackbarProvider>
									</RoomContext.Provider>
								</PersistGate>
							</RawIntlProvider>
						</MuiThemeProvider>
					</Provider>,
					document.getElementById('multiparty-meeting')
				);
			}
		}
	 });
}

serviceWorker.unregister();
